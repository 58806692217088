import React from 'react';
import Image, { getImageProps } from 'next/image';
import { cmsImage } from '../../images';
import { ImageField } from '@homeflow/next/state';

type ImageProps = {
  image: ImageField;
  dimensions?: { height: number; width: number; media?: string }[];
  options?: { loading?: 'eager' | 'lazy'; priority?: boolean };
  className?: string;
};

const BLUR_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN89OLDfwAJKQO7AawoGwAAAABJRU5ErkJggg==';

/**
 * This ResponsiveImage component combines the optimization of the NextJS Image
 * component with our cmsImage function, which works with the resizing capabilities
 * of the MrRichards API.
 *
 * For most use cases, the NextJS Image will be more than sufficient. However,
 * as we cannot control the srcset of the NextJS Image there may be cases where
 * the aspect ratio of the rendered img mean we are requesting a large image, much
 * of which is being cropped out of view. In this case there may be more room
 * for improvement which the ResponsiveImage can provide.
 *
 * The default dimensions are suitable for fullscreen hero images where the height
 * on smaller screen devices is greater than the width (the widths are based off
 * the NextJS default srcset) but can be overwritten with the `dimensions` prop.
 *
 * @example
 * ```tsx
 * <ResponsiveImage
 *   image={{ src: property.mainPhoto, alt: property.displayAddress }}
 *   dimensions={[
 *      { height: 350, width: 240 },
 *      { height: 720, width: 540 },
 *      { height: 400, width: 900 },
 *   ]}
 *   options={{ loading: 'eager' }}
 * />
 * ```
 *
 * The default dimensions assume that the images are full width of the screen by
 * using the width of each `dimension` to set the media breakpoint. `dimensions`
 * which set only `width` and `height` will follow this format. However, an
 * optional `media` field can be added to each `dimension` object to set breakpoints
 * separate from the image width.
 *
 * @example
 * ```tsx
 * <ResponsiveImage
 *   image={{ src: property.mainPhoto, alt: property.displayAddress }}
 *   dimensions={[
 *      { height: 350, width: 240, media: '(max-width: 640px)' },
 *      { height: 720, width: 540, media: '(max-width: 720px)' },
 *      { height: 400, width: 900, media: '(max-width: 1200px)' },
 *   ]}
 * />
 *
 * ```
 */

export default function ResponsiveImage({ image, dimensions, options, className }: ImageProps) {
  const sourceDimensions = dimensions || [
    { height: 800, width: 400 },
    { height: 1000, width: 640 },
    { height: 1000, width: 750 },
    { height: 1000, width: 828 },
    { height: 1500, width: 1080 },
    { height: 1500, width: 1200 },
    { height: 2000, width: 2048 },
    { height: 2000, width: 3840 },
  ];

  const largest = sourceDimensions[sourceDimensions.length - 1];

  if (
    !(
      image?.src?.includes('homeflow-assets.co.uk') ||
      image?.src?.includes('homeflow.co.uk') ||
      image?.src?.match(/files\/(site_asset|property|photo|floorplan)\/image/g)
    )
  ) {
    return (
      <Image
        className={className || 'absolute object-cover w-full h-auto'}
        src={image.src || ''}
        fill
        alt={image.alt}
        {...(options?.priority && { priority: options.priority })}
        placeholder="blur"
        blurDataURL={BLUR_IMAGE}
        sizes="
          100vw
        "
      />
    );
  }

  return (
    <picture className="h-full w-full">
      {sourceDimensions.map(({ height, width, media }) => (
        <source
          key={`${height}${width}`}
          media={media || `(max-width: ${width}px)`}
          srcSet={
            getImageProps({
              src: cmsImage(image?.src, { height, width }) || '',
              height,
              width,
              alt: image.alt,
              ...(options?.priority && { priority: options.priority }),
            }).props.srcSet
          }
        />
      ))}
      <img
        className={className || 'h-full w-full object-cover'}
        {...getImageProps({
          src: cmsImage(image?.src, largest) || '',
          height: largest.height,
          width: largest.width,
          alt: image.src,
          ...(options?.priority && { priority: options.priority }),
        }).props}
        alt={image.src} // alt added twice to keep TS happy
        {...(!options?.priority && { loading: options?.loading || 'lazy' })}
      />
    </picture>
  );
}
