/**
 * Takes an image path and generates a valid Homeflow CDN URL.
 * If a width and height are provided, the image will be resized.
 */
export default function cmsImage(
  image: string | { image: string; description: string } | null | undefined,
  options?: {
    width?: number;
    height?: number;
    imageId?: number;
    isDbapiImage?: boolean;
    md5?: string | null;
  }
): string {
  if (!image) return '';
  if (options?.isDbapiImage && !options?.imageId) return '';

  // image seems to sometimes come back from API as a string and sometimes an
  // object, so we need to cover both cases
  let path = typeof image === 'string' || image instanceof String ? (image as string) : image.image;

  if (path[0] !== '/' && !path.includes('https')) path = `/${path}`;

  const dimensions = `${options?.width ? options.width : '_'}x${
    options?.height ? options.height : '_'
  }`;

  /**
   * This will be used to add dimensions to a path that has no dimensions or _x_ values.
   * For example /files/photos/12345/6789/my-photo.jpg => /files/photos/12345/6789/_x_/my-photo.jpg
   */
  function addDimensionsToPathWithoutSize(string: string): string {
    const lastSlashIndex = string.lastIndexOf('/');
    return `${string.substring(0, lastSlashIndex + 1)}${dimensions}${string.substring(
      lastSlashIndex
    )}`;
  }

  let resizedPath = '';
  const hasDimensions = /\/(\d+|_)x(\d+|_)\//.test(path);

  /**
   * This covers:
   * - Dimensions in format of 1000x1000
   * - Dimensions in format of _x1000
   * - Dimensions in format of 1000x_
   * - Dimensions in format of _x_
   */
  if (hasDimensions) {
    resizedPath = path.replace(/\/(\d+|_)x(\d+|_)\//, `/${dimensions}/`).trim();
  }

  /**
   * This covers:
   * - A path with no dimensions or _x_ values /files/photos/12345/6789/my-photo.jpg
   */
  if (!hasDimensions) {
    resizedPath = addDimensionsToPathWithoutSize(path);
  }

  // URL is absolute
  if (resizedPath.includes('//')) return resizedPath;

  const cleanPath = resizedPath[0] === '/' ? resizedPath.substring(1) : resizedPath;

  function addFilepathAndId(id: number) {
    let imageId = id.toString();
    if (imageId.length < 8) {
      imageId = imageId.padStart(8, '0');
    }

    if (options?.md5) {
      return `files/site_asset/image/${imageId.substring(
        0,
        imageId.length - 4
      )}/${imageId.substring(imageId.length - 4)}/${options.md5}/`;
    }

    return `files/site_asset/image/${imageId.substring(0, imageId.length - 4)}/${imageId.substring(
      imageId.length - 4
    )}/`;
  }

  return `https://mr0.homeflow-assets.co.uk/${
    options?.isDbapiImage && options?.imageId ? addFilepathAndId(options.imageId) : ''
  }${cleanPath}`;
}
