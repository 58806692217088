/* Mutating params is how redux toolkit works */
/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { User } from './../../models';

export interface SavedSearch {
  alert_frequency?: string;
  channel: string;
  county_id?: number | null;
  id?: number;
  is_implied?: boolean;
  location_id?: number | null;
  max_bedrooms?: number | null;
  min_bedrooms?: number | null;
  max_price?: number | null;
  min_price?: number | null;
  min_square_feet?: number | null;
  name?: string;
  place?: any; // TODO
  query?: string | null;
  saved?: boolean;
  status?: string | null;
  tags?: string[];
  place_id?: string | null;
  key?: string;
}

export type UserState = {
  currentUser: User | null;
};

const initialState: UserState = {
  currentUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUser } = userSlice.actions;

export const selectCurrentUser = (state: { user: UserState }) => state.user?.currentUser;

export default userSlice.reducer;
